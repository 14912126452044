<template>
  <div class="setting-item s-min-max-date">
    <esmp-datepicker-adaptive
      v-model="minMaxDate"
      :placeholder="localSetting.name"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'SMinMaxDate',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    minMaxDate: {
      get() {
        return this.localSetting.value ? new Date(this.localSetting.value) : null;
      },
      set(value) {
        this.localSetting.value = value;
      },
    },
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', dayjs(value).format());
      },
    },
  },
};
</script>
